import { Injectable,Injector } from '@angular/core';
import {TranslateLoader} from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

@Injectable()
export class LanguageLoader implements TranslateLoader {
    constructor(private http: HttpClient) { }
    getTranslation(lang: string): Observable<any> {
        console.log("this is lang ", lang);
        let value = lang.split('-');
        let panel = value[0];
        // lang  = value[1];
        console.log("this is lang ", lang, panel);
        return this.http.get(`./assets/lang/${lang}.json`);
    }
}