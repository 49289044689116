import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ApiService } from "src/app/core/services/api.service";
import { ResultModel } from "src/app/core/models/response/base.model";

@Injectable()
export class HomePageService {
  constructor(private apiService: ApiService) {}

 
  getHotelInfo(reqModel): Observable<ResultModel> {
      const route = "/master/getHotelInformation";
      return this.apiService.get<ResultModel>(route, reqModel);
    }

  addVisitor(reqModel): Observable<ResultModel> {
      const route = "/master/addVisitorForm";
      return this.apiService.post<ResultModel>(route, reqModel);
    }
}
