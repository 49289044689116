import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'butlermax';
  constructor(private translate: TranslateService) {
    this.translate.setDefaultLang('en');
    const lang = window.localStorage.getItem('userLangTranslate');
    if(!lang){
      window.localStorage.setItem('userLangTranslate','en');
      this.translate.use('en');
    }else{
      this.translate.use(lang);
    }
  }
  ngOnInit(){
    
  }
}
