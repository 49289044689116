import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { RouterModule } from "@angular/router";
import { LanguageLoader } from "./classes/CustomLoader";
import { CoreRoutingModule } from "./core-routing.module";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { WINDOW_PROVIDERS } from './window.provider';
import { GlobalService } from "./services/global.service";
import { Globals } from './globals';
import { HomePageService } from './services/homepage.service';
import { ApiService } from './services/api.service';
import {JwtService} from './services/jwt.service'
import { ToastrModule } from "ngx-toastr";
import { CheckedInService } from "./services/checked-in.service";
import { MasterService } from "./services/master.service";




@NgModule({
    declarations: [    
  ],
    imports: [
      CommonModule,
      HttpClientModule,
      RouterModule,
      CoreRoutingModule,
      ToastrModule.forRoot({
        timeOut: 3000,
        positionClass: "toast-bottom-right",
        preventDuplicates: true
    }),
      TranslateModule.forChild({
        loader: {
          provide: TranslateLoader,
          useClass: LanguageLoader
        }
      }),
    ],
    exports: [
    //   LanguageTranslationComponent,
    //   LanguageDirective,
    //   LanguageSaveComponent,
      
    ],
    providers: [
        GlobalService, WINDOW_PROVIDERS,Globals,HomePageService,ApiService,MasterService,JwtService,CheckedInService
    ]
  })
  export class CoreModule {}