import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResultModel } from 'src/app/core/models/response/base.model';
import { ApiService } from 'src/app/core/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class CheckedInService {

  constructor(private apiService: ApiService) { }

  signupGuest(reqModel): Observable<ResultModel> {
    const route = '/master/butlerMaxSignUp';
    return this.apiService.post<ResultModel>(route, reqModel);
  }
  getUserDetail(reqModel): Observable<ResultModel> {
    const route = '/master/getUserDetails';
    return this.apiService.get<ResultModel>(route, reqModel);
  }

  getLoginUserDetail(reqModel): Observable<ResultModel> {
    const route = '/master/getLoginUserDetails';
    return this.apiService.get<ResultModel>(route, reqModel);
  }
  userOTPVerification(reqModel): Observable<ResultModel> {
    const route = '/user/userOtpVerification';
    return this.apiService.post<ResultModel>(route, reqModel);
  }
  resendOTPMobile(reqModel): Observable<ResultModel> {        // used in butlermax signup process too
    const route = '/guest/resendOtp';
    return this.apiService.post<ResultModel>(route, reqModel);
  }
  sendOTPMobile(reqModel): Observable<ResultModel> {          // used in butlermax signup process too
    const route = '/guest/sendOtp';
    return this.apiService.post<ResultModel>(route, reqModel);
  }
  userEmailVerification(reqModel): Observable<ResultModel> {
    const route = '/hotel/UserEmailVerification';
    return this.apiService.post<ResultModel>(route, reqModel);
  }

  login2faVerification(reqModel): Observable<ResultModel> {
    const route = '/user/twoFAUserVerification';
    return this.apiService.post<ResultModel>(route, reqModel);
  }

  userTwoFAEmailSendOtp(reqModel): Observable<ResultModel> {
    const route = '/user/UserTwoFAEmailSendOtp';
    return this.apiService.post<ResultModel>(route, reqModel);
  }

  userTwoFAEmailVerification(reqModel): Observable<ResultModel> {
    const route = '/user/UserTwoFAEmailVerification';
    return this.apiService.post<ResultModel>(route, reqModel);
  }

  UserEmailVerifyForgetPass(reqModel): Observable<ResultModel> {
    const route = '/master/UserEmailVerifyForgetPass';
    return this.apiService.post<ResultModel>(route, reqModel);
  }

  resend2faVerification(reqModel): Observable<ResultModel> {
    const route = '/user/twoFAResendOtpUser';
    return this.apiService.post<ResultModel>(route, reqModel);
  }

  passReset(reqModel): Observable<ResultModel> {
    const route = '/master/passReset';
    return this.apiService.post<ResultModel>(route, reqModel);
  }
  
  userEmailActivation(reqModel): Observable<ResultModel> {
    const route = '/hotel/UserEmailActivation';
    return this.apiService.post<ResultModel>(route, reqModel);
  }
  ChangeMobileNo(reqModel): Observable<ResultModel> {
    const route = '/master/updateUserNum';
    return this.apiService.post<ResultModel>(route, reqModel);
  }
  subscribeLetter(reqModel): Observable<ResultModel> {
    const route = '/master/newsLetterSubscriber';
    return this.apiService.post<ResultModel>(route, reqModel);
  }

  subdomainUnique(reqModel): Observable<ResultModel> {
    const route = '/hotel/is-hotel-unique';
    return this.apiService.post<ResultModel>(route, reqModel);
  }
  signIn(reqModel): Observable<ResultModel> {
    const route = '/master/butlerMaxLogin';
    return this.apiService.post<ResultModel>(route, reqModel);
  }

  matchCurrentPass(reqModel): Observable<ResultModel> {
    const route = "/master/matchButlerMaxUserPass";
    return this.apiService.post<ResultModel>(route, reqModel);
  }
  
  changePass(reqModel): Observable<ResultModel> {
    const route = "/master/changeButlerMaxUserPass";
    return this.apiService.post<ResultModel>(route, reqModel);
  }

  updateUserProfile(reqModel): Observable<ResultModel> {
    const route = "/master/updateButlerMaxUserProfile";
    return this.apiService.post<ResultModel>(route, reqModel);
  }

  manageTwoFA(reqModel): Observable<ResultModel> {
    const route = "/master/manageTwoFA";
    return this.apiService.post<ResultModel>(route, reqModel);
  }

  subscriptionInformation(reqModel): Observable<ResultModel> {
    const route = "/master/subscriptionInformation";
    return this.apiService.post<ResultModel>(route, reqModel);
  }
  getsubscriptionInformation(reqModel): Observable<ResultModel> {
    const route = "/master/getsubscriptionInformation";
    return this.apiService.get<ResultModel>(route, reqModel);
  }

  getsubscriptionPlanDetails(reqModel): Observable<ResultModel> {
    const route = "/master/getsubscriptionPlanDetails";
    return this.apiService.get<ResultModel>(route, reqModel);
  }
  
  getPaymentConnection(reqModel): Observable<ResultModel> {
    const route = "/payment/ButlerMaxPaymentMethodsConnection";
    return this.apiService.get<ResultModel>(route, reqModel);
  }

  subscriptionCheckout(reqModel): Observable<ResultModel> {
    const route = "/payment/subscriptionCheckOut";
    return this.apiService.post<ResultModel>(route, reqModel);
  }
  
  PaypalCheckout(reqModel) :Observable<ResultModel>{
    const route = '/payment/subscriptionPaypalExecute';
    return this.apiService.post<ResultModel>(route, reqModel)
   }

  updateProfileImageButlerMax(reqModel) :Observable<ResultModel>{
    const route = '/masater/editProfileImageButlermax';
    return this.apiService.post<ResultModel>(route, reqModel)
   }

   butlermaxSendOTPMobile(reqModel): Observable<ResultModel> {
    const route = '/guest/sendOtpButlerMaxUser';
    return this.apiService.post<ResultModel>(route, reqModel);
  }
   butlermaxresendOTPMobile(reqModel): Observable<ResultModel> {
    const route = '/guest/resendOtpButlerMaxUser';
    return this.apiService.post<ResultModel>(route, reqModel);
  }
  
}
