import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { environment } from "../../../environments/environment";
import { JwtService } from "./jwt.service";
import { GlobalService } from "./global.service";
import { Router } from "@angular/router";
//
@Injectable()
export class ApiService {
  constructor(
    private router: Router,
    private http: HttpClient,
    private jwtService: JwtService,
    public globalService: GlobalService
  ) {}

  private setHeaders(): HttpHeaders {
    const headersConfig = {
      "Content-Type": "application/json",
      Accept: "application/json"
    };
    const authToken = this.jwtService.getToken();
    if (authToken.length > 0) {
      headersConfig["Authorization"] = authToken;
    }
    return new HttpHeaders(headersConfig);
  }

  private formatErrors(error: any) {
    return throwError(error);
  }

  get<ResultModel>(
    path: string,
    searchParams: HttpParams = new HttpParams()
  ): Observable<ResultModel> {
    if (searchParams) {
      const activatedRoute = this.router.url.split("/");
      Object.assign(searchParams, {
        Subdomain: this.globalService.getHostname(),
        Route: activatedRoute[activatedRoute.length - 1]
      });

      if (this.globalService.getLangAccToUser()) {
        Object.assign(searchParams, {
          LangKey: this.globalService.getLangAccToUser()
        });
      }
    }

    return this.http
      .get<ResultModel>(`${environment.apiUrl}${path}`, {
        headers: this.setHeaders(),
        params: searchParams
      })
      .pipe(catchError(this.formatErrors));
  }

  put<ResultModel>(path: string, body: object = {}): Observable<ResultModel> {
    if (body) {
      Object.assign(body, { Subdomain: this.globalService.getHostname() });

      if (this.globalService.getLangAccToUser()) {
        Object.assign(body, { LangKey: this.globalService.getLangAccToUser() });
      }
    }

    return this.http
      .put<ResultModel>(`${environment.apiUrl}${path}`, JSON.stringify(body), {
        headers: this.setHeaders()
      })
      .pipe(catchError(this.formatErrors));
  }

  post<ResultModel>(path: string, body: Object = {}): Observable<ResultModel> {
    if (body) {
      Object.assign(body, { Subdomain: this.globalService.getHostname() });

      if (this.globalService.getLangAccToUser()) {
        Object.assign(body, { LangKey: this.globalService.getLangAccToUser() });
      }
    }

    return this.http
      .post<ResultModel>(`${environment.apiUrl}${path}`, body, {
        headers: this.setHeaders()
      })
      .pipe(catchError(this.formatErrors));
  }

  patch<ResultModel>(path: string, body: Object = {}): Observable<ResultModel> {
    if (body) {
      Object.assign(body, { Subdomain: this.globalService.getHostname() });

      if (this.globalService.getLangAccToUser()) {
        Object.assign(body, { LangKey: this.globalService.getLangAccToUser() });
      }
    }

    return this.http
      .patch<ResultModel>(`${environment.apiUrl}${path}`, body, {
        headers: this.setHeaders()
      })
      .pipe(catchError(this.formatErrors));
  }

  /*delete<ResultModel>(path: string): Observable<ResultModel> {
    return this.http.delete<ResultModel>(`${environment.apiUrl}${path}`, { headers: this.setHeaders() })
      .pipe(catchError(this.formatErrors));
  }*/

  delete<ResultModel>(
    path: string,
    params: HttpParams = new HttpParams()
  ): Observable<ResultModel> {
    if (params) {
      Object.assign(params, { Subdomain: this.globalService.getHostname() });

      if (this.globalService.getLangAccToUser()) {
        Object.assign(params, {
          LangKey: this.globalService.getLangAccToUser()
        });
      }
    }

    return this.http
      .delete<ResultModel>(`${environment.apiUrl}${path}`, {
        headers: this.setHeaders(),
        params: params
      })
      .pipe(catchError(this.formatErrors));
  }
}
