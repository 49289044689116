import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
const routes: Routes = [
  { path: "",
   loadChildren: ()=> import('./Users/users.module').then(m => m.UsersModule)},

  {
    path: "superAdmin",
    loadChildren: ()=> import('./super-admin/super-admin.module').then(m => m.SuperAdminModule) /* "./super-admin/super-admin.module#SuperAdminModule" canLoad: [ConsumerNavGuard] */
  },

    // { path: "**", component: PageNotFoundComponent }

];

@NgModule({
  imports: [RouterModule.forRoot(routes,{})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
