import { Injectable, EventEmitter } from "@angular/core";
import { ApiService } from "./api.service";
import { Observable, throwError } from "rxjs";
import { ResultModel } from "../models/response/base.model";
import { HttpEvent, HttpRequest, HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { JwtService } from "./jwt.service";
import { HttpHeaders } from "@angular/common/http";
import { Globals } from "../globals";
import { catchError } from "rxjs/operators";
import { GlobalService } from "./global.service";
@Injectable()
export class MasterService {
  galleryData = new EventEmitter<any>();
  profileData = new EventEmitter<any>();
  userSwitchLang = new EventEmitter<any>();
  manageBrandingData = new EventEmitter<any>();
  langCustomizeEmit = new EventEmitter<any>();

  constructor(
    private apiService: ApiService,
    private http: HttpClient,
    private jwtService: JwtService,
    public globals: Globals,
    private globalService: GlobalService
  ) {}

  private setAwsHeaders(): Headers {
    const headersConfig = {
      "Content-Disposition": "attachment",
      "x-amz-server-side-encryption": "AES256"
    };
    return new Headers(headersConfig);
  }

  private formatErrors(error: any) {
    return throwError(error);
  }

  gethotelLangTranslate(): string {
    if (!window.localStorage["hotelLangTranslate"]) {
      return this.globals.hotelLangTranslate;
    } else {
      return window.localStorage["hotelLangTranslate"];
    }
  }

  getuserLangTranslate(): string {
    if (!window.localStorage["userLangTranslate"]) {
      return this.globals.userLangTranslate;
    } else {
      return window.localStorage["userLangTranslate"];
    }
  }

  getLangSaveProperty() {
    if (!window.localStorage["langSaveProperty"]) {
      return this.globals.langSaveProperty;
    } else {
      return window.localStorage["langSaveProperty"];
    }
  }
  getLangSaveValue() {
    if (!window.localStorage["langSaveValue"]) {
      return this.globals.langSaveValue;
    } else {
      return window.localStorage["langSaveValue"];
    }
  }

  getAwsSignedUrl(data): Observable<ResultModel> {
    const route = "/hotel/getAwsSignedUrl";
    return this.apiService.post<ResultModel>(route, data);
  }

  putObjectIntoBucket(data: any, file: any): Observable<HttpEvent<{}>> {
    const formdata: FormData = new FormData();
    formdata.append("file", file);

    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": file.type,
        "x-amz-acl": "private",
        responseType: "text",
        observe: "response"
      })
    };
    const req = new HttpRequest("PUT", data, file, httpOptions);

    return this.http.request(req);
    /*return this.http.put(data, formdata, httpOptions)
    .pipe(catchError(this.formatErrors));*/
    //return this.http.put(data, formdata, httpOptions);
  }

  deleteObjectFromBucket(data: any, imgType: string): Observable<{}> {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": imgType,
        "x-amz-acl": "private",
        responseType: "text",
        observe: "response"
      })
    };
    // const req = new HttpRequest('DELETE',data,httpOptions);

    // return this.http.request(req);

    return this.http.delete(data).pipe(catchError(this.formatErrors));
  }


}
